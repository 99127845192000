import React from 'react';
import Grid from '@mui/material/Grid2'
import HeaderBlock from './HeaderBlock';
import blockData from '../data/headerBlockData';
import Badges from 'components/Badges/Badges';
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const HomeHeader=({rowData})=> {

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Grid className='background-video-container' sx={{
            minHeight: { xs: '170vh', sm: '100vh' },
        }}>
            <video autoPlay muted loop className="background-video">
                <source src="https://cdn.pixfuture.com/video/853870-hd_1920_1080_25fps.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="overlay"></div>
            <Grid className='content' sx={{
                marginTop: { sm: '7%' },
            }}>
                <h1 style={{ fontSize: isMobile ? '35px' : '60px', marginBottom: '-20px' }} >{rowData?.title}</h1>
                <p>Connect directly with adinify's global advertisers and agencies, powered by an advanced machine learning platform</p>
                <Grid container
                    justifyContent={'center'}
                    spacing={2}
                    marginTop='10px'
                    sx={{
                        flexDirection: { xs: 'column', sm: 'row' }
                    }}
                >
                    <HeaderBlock { ...blockData[0] } />
                    <HeaderBlock { ...blockData[1] } />
                </Grid>
                <Badges/>
            </Grid>
        </Grid>
    );
}
export default HomeHeader;