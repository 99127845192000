import React from 'react'
import badgesData from 'data/Badges/badgesData'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const chunkArray = (arr, chunkSize) => {
  const chunks = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize))
  }
  return chunks;
}

const Badges = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const rows = chunkArray(badgesData, 4)

  return (
    <div style={{ marginTop: '20px' }}>
      
    { isMobile ? rows.map((row, rowIndex) => (
      <div
        key={rowIndex}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
          marginBottom: '20px'
        }}
      >
        {row.map(item => (
          <a href={item.link} key={item.id}>
            <img
              src={item.src}
              alt="badge"
              style={{ width: isMobile ? item.mobileWidth : item.desktopWidth, height: isMobile ? item.mobileHeight : item.desktopHeight }}
            />
          </a>
        ))}
      </div>
    )) :
      <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '20px'
      }}
    >
      {badgesData.map(item => (
        <a href={item.link} key={item.id}>
          <img
            src={item.src}
            alt="badge"
            style={{ width: item.desktopWidth, height: item.desktopHeight }}
          />
        </a>
      ))}
    </div>
    }
  </div>
  )
}

export default Badges
