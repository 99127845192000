import React from 'react'
import Grid from '@mui/material/Grid2'
import { Button, Link } from '@mui/material';


const HeaderBlock = ({ header, preHeader, text }) => {
    return (
            <Grid 
                item 
                container
                sx={{
                    backgroundColor: "rgba(0, 59, 162, 0.5)",
                    minHeight: "150px",
                    maxWidth: { xs: "100%", sm: "45%" },
                    textAlign: "left",
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start"
                }}
            >
                <Grid container spacing={0.5}>
                    <Grid 
                        backgroundColor="rgb(0, 135, 198)" 
                        width="auto" 
                        borderRadius="15px" 
                        marginBottom="10px" 
                        padding="5px 15px" 
                        display="inline-block"
                    >
                        <h4 style={{ fontWeight: "bold", fontSize: "23px" }}>
                            { header }
                        </h4>
                    </Grid>
                    <h5 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        { preHeader }
                    </h5>
                    <h6 style={{ fontSize: "15px" }}>
                        { text }
                    </h6>
                </Grid>
                
                <Link href="#get-started" rel="noreferrer">
                    <Button
                        sx={{
                            color: "#fff",
                            width: 110,
                            height: 50,
                            textTransform: "none",
                            borderRadius: "15px",
                            backgroundColor: "#242424",
                            marginTop: '15px',
                            transition: "box-shadow 0.4s ease-in-out, background-color 0.5s ease-in-out",
                            "&:hover": {
                                boxShadow: "0 0 5px 6px rgba(126, 126, 126, 0.1)",
                                backgroundColor: "#363636",
                                display: "inline-block"
                            }
                        }}
                    >
                        Learn more
                    </Button>
                </Link>
            </Grid>
    )
}


export default HeaderBlock