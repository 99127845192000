const blockData = [
    {
        header: 'App developers',
        preHeader: 'A dependable platform to increase and optimize your mobile advertising revenue',
        text: 'Gain complete control, flexibility, and transparency in your advertising business. Now is the time to build and grow your successful venture.',
    },
    {
        header: 'Demands and agencies',
        preHeader: 'Reach worldwide in-app audiences and grow your global presence.',
        text: 'Access high-quality, direct, and brand-safe in-app inventory for all ad formats, carefully optimized for the best performance and maximum impact.',
    },
]

export default blockData