const partners = [
    { link: "/images/partners/PubMaticLogo.png", desktopWidth: '140px', desktopHeight: '20px', mobileWidth: '180px', mobileHeight: '25px'  },
    { link: "/images/partners/OneTagLogo.png", desktopWidth: '140px', desktopHeight: '40px', mobileWidth: '140px', mobileHeight: '40px' },
    { link: "/images/partners/SmileWantedLogo.png", desktopWidth: '230px', desktopHeight: '40px', mobileWidth: '230px', mobileHeight: '40px' },
    { link: "/images/partners/SmartyAdsLogo.jpg", desktopWidth: '160px', desktopHeight: '40px', mobileWidth: '160px', mobileHeight: '40px' },
    { link: "/images/partners/AppNexusLogo.png", desktopWidth: '175px', desktopHeight: '40px', mobileWidth: '160px', mobileHeight: '40px' },
    { link: "/images/partners/MinuteMediaLogo.webp", desktopWidth: '230px', desktopHeight: '130px', mobileWidth: '230px', mobileHeight: '130px' },
    { link: "/images/partners/AdtelligentLogo.png", desktopWidth: '150px', desktopHeight: '42px', mobileWidth: '140px', mobileHeight: '40px' },
    { link: "/images/partners/NexxenLogo.png", desktopWidth: '150px', desktopHeight: '38px', mobileWidth: '140px', mobileHeight: '34px' },
    { link: "/images/partners/GoogleAdMobLogo.webp", desktopWidth: '250px', desktopHeight: '150px', mobileWidth: '200px', mobileHeight: '100px' },
    { link: "/images/partners/SmaatoLogo.jpg", desktopWidth: '150px', desktopHeight: '42px', mobileWidth: '140px', mobileHeight: '40px' },
    { link: "/images/partners/NoBidLogo.webp", desktopWidth: '70px', desktopHeight: '72px', mobileWidth: '70px', mobileHeight: '72px' },
    { link: "/images/partners/E-PlanningLogo.png", desktopWidth: '170px', desktopHeight: '50px', mobileWidth: '170px', mobileHeight: '50px' },
    { link: "/images/partners/MediaNetLogo.png", desktopWidth: '150px', desktopHeight: '42px', mobileWidth: '140px', mobileHeight: '35px' },
    { link: "/images/partners/ShareThroughLogo.png", desktopWidth: '220px', desktopHeight: '35px', mobileWidth: '200px', mobileHeight: '32px' },
    { link: "/images/partners/SovrnLogo.webp", desktopWidth: '100px', desktopHeight: '30px', mobileWidth: '100px', mobileHeight: '32px' },
    { link: "/images/partners/MegiaGridLogo.png", desktopWidth: '75px', desktopHeight: '55px', mobileWidth: '75px', mobileHeight: '55px' },
    { link: "/images/partners/MagniteLogo.png", desktopWidth: '140px', desktopHeight: '45px', mobileWidth: '140px', mobileHeight: '45px' },
    { link: "/images/partners/CriteoLogo.png", desktopWidth: '155px', desktopHeight: '35px', mobileWidth: '155px', mobileHeight: '35px' },
    { link: "/images/partners/IronSourceLogo.png", desktopWidth: '170px', desktopHeight: '40px', mobileWidth: '170px', mobileHeight: '42px' },
    { link: "/images/partners/MolocoLogo.png", desktopWidth: '180px', desktopHeight: '75px', mobileWidth: '155px', mobileHeight: '65px' },
    { link: "/images/partners/EpsilonLogo.png", desktopWidth: '115px', desktopHeight: '35px', mobileWidth: '120px', mobileHeight: '38px' },
    { link: "/images/partners/GoogleLogo.webp", desktopWidth: '140px', desktopHeight: '45px', mobileWidth: '140px', mobileHeight: '45px' },
    { link: "/images/partners/OpenXLogo.png", desktopWidth: '140px', desktopHeight: '70px', mobileWidth: '140px', mobileHeight: '65px' },
    { link: "/images/partners/SonobiLogo.png", desktopWidth: '140px', desktopHeight: '50px', mobileWidth: '140px', mobileHeight: '45px' },
    { link: "/images/partners/33acrossLogo.png", desktopWidth: '150px', desktopHeight: '25px', mobileWidth: '140px', mobileHeight: '25px' },
]

export default partners