const badgesData = [
    {
        src: '/images/badges/home-badge-1.png',
        id: 1,
        desktopWidth: 'auto',
        desktopHeight: '45px',
        mobileWidth: 'auto',
        mobileHeight: '26px',
        link: 'https://developers.google.com/third-party-ads/googleads-vendors'
    },
    {
        src: '/images/badges/home-badge-2.png',
        id: 2,
        desktopWidth: 'auto',
        desktopHeight: '58px',
        mobileWidth: 'auto',
        mobileHeight: '37px',
        link: 'https://iabeurope.eu/?s=pixfuture&post_type=vendor'
    },
    {
        src: '/images/badges/home-badge-3.png',
        id: 3,
        desktopWidth: 'auto',
        desktopHeight: '50px',
        mobileWidth: 'auto',
        mobileHeight: '25px',
        link: 'https://www.dnb.com/business-directory/company-profiles.pixfuture_media_inc.5e62bee9419dbb3f134205b33b10f85d.html'
    },
    {
        src: '/images/badges/home-badge-4.png',
        id: 4,
        desktopWidth: 'auto',
        desktopHeight: '40px',
        mobileWidth: 'auto',
        mobileHeight: '25px',
        link: 'https://docs.prebid.org/dev-docs/bidders/adinify.html'
    },
    {
        src: '/images/badges/home-badge-5.png',
        id: 5,
        desktopWidth: 'auto',
        desktopHeight: '40px',
        mobileWidth: 'auto',
        mobileHeight: '34px',
        link: 'https://digiday.com/announcement/awards/in-the-know-vox-media-insider-and-theskimm-are-among-this-years-digiday-media-awards-finalists/'
    },
]

export default badgesData