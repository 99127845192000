import React from 'react';
import './partnersCarousel.css';
import partners from 'data/partnersCarousel/partnersCarousel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const PartnersCarousel = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className="marquee-container">
      <div className="marquee-content">
        {partners.map((data, index) => (
          <span className="marquee-item" key={`item-${index}`}>
            <img
              src={data.link}
              style={{ width: isMobile ? data.mobileWidth : data.desktopWidth, height: isMobile ? data.mobileHeight : data.desktopHeight }}
              alt={`partner-${index}`}
            />
          </span>
        ))}
        {partners.map((data, index) => (
          <span className="marquee-item" key={`item-duplicate-${index}`}>
            <img
              src={data.link}
              style={{ width: isMobile ? data.mobileWidth : data.desktopWidth, height: isMobile ? data.mobileHeight : data.desktopHeight }}
              alt={`partner-duplicate-${index}`}
            />
          </span>
        ))}
      </div>
    </div>
  );
};

export default PartnersCarousel;