import React, { useEffect, useState } from 'react';
import '../navbar.css';
import Grid from '@mui/material/Grid2'
import { Button, Link } from '@mui/material';
import LogoSvgWhite from 'components/LogoSvg/LogoSvgWhite';
import LogoSvg from 'components/LogoSvg/LogoSvg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import { WideContainer } from 'components/Base/MUI/containers/PxftContainer';
import { useLocation } from 'react-router-dom';

const NavigationBar = () => {
const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down('md'));
const navigate = useNavigate();

const [darkTheme, setDarkTheme] = useState(false)

let location = useLocation();

useEffect(()=>{
  const darkThemeUrl = [
    '/'
  ]
  if(darkThemeUrl.includes(location.pathname)){
    setDarkTheme(true)
  }else setDarkTheme(false)
},[location])

const WindowMenu= () =>{
return(
  <Grid container className='nav-navi-contents'>
    <Grid size={{md:9,xs:9}}>
      <Grid container justifyContent={{md:'left',xs:'space-between'}} className='center-align'>
        <Grid pr={4} display='flex' alignItems='center' justifyContent='left' className='center-align navi-logo' onClick={()=>{navigate("/")}}>
          {
            darkTheme?<LogoSvgWhite/> :<LogoSvg/>
          }
        </Grid>
      </Grid>
    </Grid>
    <Grid size={{md:3,xs:3}} className='center-align' style={{justifyContent:'flex-end'}}>
    {!isMobile &&
      <Link href='https://portal.adinify.com/' rel="noreferrer" className='navibtn'>
        <Button 
          sx={{ 
            color: 'white', 
            width: 100, 
            height: 50, 
            mx: 1,
            marginTop: '10px',
            borderRadius: '10px',
            backgroundColor: 'rgb(0, 59, 162)', 
            "&:hover": { 
              backgroundColor: 'rgb(0, 63, 165)',
              boxShadow: '0px 0px 20px rgb(0, 57, 118)',
            }, 
            transition: '0.5s ease'
          }}
        >
          Sign in
        </Button>
      </Link>
    }
    {
      isMobile && <MobileMenu/>
    }
    </Grid>
    </Grid>
);
}
    return(
      <div>
        <WideContainer className='nav'>
          <div className='center-align'>
              {WindowMenu()}
          </div>
        </WideContainer>
      </div>
    );
}
export default NavigationBar;