import React from 'react';
import './home.css'
import Grid from '@mui/material/Grid2'
import GeneralTitle from 'components/GeneralTitle/GeneralTitle';
import GeneralColumn from 'components/GeneralColumn/GeneralColumn';
import IconCardColumn from 'components/IconCardColumn/IconCardColumn';
import HomeHeader from './components/HomeHeader';
import SignUpForm from 'views/SignUp/SignUpForm';
import PartnersCarousel from 'components/PartnersCarousel/PartnersCarousel';

const Home=()=> {
    const headerContent = {
        title: `Maximize your app's revenue `,
        contents:[
            'With the help of Adinity real-time ads trading algorithm, we can efficiently increase the earnings of each publisher while earning the highest CPM for every impression.',
            'By simultaneoysly calling all participating networks, your ad sources can bid on each impression in real-time, ensuring you always get the highest paying adviser.'
        ],
        imgSrc:'/images/home/header.png',
        imgSrc2x:'/images/home/header@2x.png'
    }
    const contentRows = [
            {
                title: 'Best-in-class Ad Monetization',
                cards:[
                    {
                        imgSrc:'/images/home/icon-1.png',
                        title:'Premium buyers',
                        content:'Increase your overall earnings by utilizing real-time bidding and gaining access to the largest advertising sources.'
                    },
                    {
                        imgSrc:'/images/home/icon-2.png',
                        title:'Direct PMP sales',
                        content:'Access direct branded campaigns managed by Adinify to guarantee the highest sale of inventory directly to branded advertisers.'
                    },
                    {
                        imgSrc:'/images/home/icon-3.png',
                        title:'Optimization technology',
                        content:'Leverage a secure environment to build trust, enhance user retention, and ensure sustained growth of your advertising revenue.'
                    },
                ]
            },
            {
                title: 'Maximize your ad revenue and grow your business',
                contents:[
                    'Adinify conducts a real-time auction for ad sources to compete for every ad impression in your mobile app, ensuring maximum value for your inventory.',
                ],
                imgSrc:'/images/home/feature-1.png',
                imgSrc2x:'/images/home/feature-1@2x.png'
            },
            {
                title: 'Get the ad formats that can enhance your monetization effect',
                contents:[
                    'We provide publishers with the most popular formats for mobile apps, helping them earn more while improving the user experience.',
                ],
            },
            {
                subtitle:'Native ads',
                title: 'Ensure a smooth user experience',
                contents:[
                    'We will provide you with ad components specially designed for your native app code so that the ads seamlessly blend in with your app‘s look and feel.',
                ],
                imgSrc:'/images/home/feature-2.png',
                imgSrc2x:'/images/home/feature-2@2x.png'
            },
            {
                subtitle:'Rewarded ads',
                title: 'Maximize engagement and ad revenue',
                contents:[
                    'Offer users the choice to interact with video ads in exchange for in-app rewards, while maintaining the flexibility to opt-out at any time. ',
                    'These rewarded ads are designed to optimize your app‘s revenue stream while ensuring user satisfaction.'
                ],
                imgSrc:'/images/home/feature-3.png',
                imgSrc2x:'/images/home/feature-3@2x.png'
            },
            {
                subtitle:'Interstitial and banner ads',
                title: 'Use flexible formats for a perfect fit',
                contents:[
                    'Our banner ads seamlessly expand to the full screen upon tapping and automatically adjust as users rotate their devices.',
                    'Interstitial ads are strategically placed at natural breaks or transition points, offering engaging brand experiences without interrupting the flow of your app.'
                ],
                imgSrc:'/images/home/feature-4.png',
                imgSrc2x:'/images/home/feature-4@2x.png'
            },
            {
                title: 'Robust reporting',
                contents:[
                    'Adinify offers wide range of analytics and reporting features, enabling you to make better decisions for your app and understand how to maximize your monetization efforts.',
                ]
            },
            {
                title: 'Monitor and analyze stats in real-time',
                contents:[
                    'Detailed statistics reports include viewability, page views,',
                    'CTR, CPM, and more to help grow revenue.'
                ],
                imgSrc:'/images/home/feature-5.png',
                imgSrc2x:'/images/home/feature-5.png'
            },
            {
                title: 'The team you can count on',
                contents:[
                    'Adinify is a platform developed and run by veterans in the ad tech industry with decades of knowledge in ad sales and ad revenue maximization.',
                ],
                cards:[
                    {
                        imgSrc:'/images/home/team-count-1.png',
                        title:'Dedicated support',
                        content:'A dedicated account manager ensures to support the publisher every step of the way to achieve the best results.'
                    },
                    {
                        imgSrc:'/images/home/team-count-2.png',
                        title:'Quality Assurance',
                        content:'We upload the highest standards in quality control for publisher traffic and the safty of their ad creatives.'
                    },
                    {
                        imgSrc:'/images/home/team-count-3.png',
                        title:'Competitive payment terms',
                        content:'We offer automated global bank transfers in local currencies and Paypal transfers.'
                    },
                ]
            },
        ]
    return (
        <Grid>
            {/* <GeneralHeader rowData={headerContent}/> */}
            <HomeHeader rowData={headerContent}/> 
            <Grid my={2}>
                <IconCardColumn rowData={contentRows[0]} imageWidth='50px'/>
            </Grid>
            <Grid my={2}>
                <GeneralColumn rowData={contentRows[1]} reverse={true}/>
            </Grid>
            <Grid my={4} py={4}>
                <GeneralTitle rowData={contentRows[2]}/>
            </Grid>
            <Grid my={2}>
                <GeneralColumn rowData={contentRows[3]}/>
                <GeneralColumn rowData={contentRows[4]} reverse={true}/>
                <GeneralColumn rowData={contentRows[5]}/>
            </Grid>
            <Grid my={4}>
                <IconCardColumn rowData={contentRows[6]}/>
            </Grid>
            <Grid my={2}>
                <GeneralColumn rowData={contentRows[7]} reverse={true}/>
            </Grid>
            <Grid my={4}>
                <IconCardColumn rowData={contentRows[8]} imageWidth='260px'/>
            </Grid>
            <Grid>
                <h2 style={{ textAlign: 'center', marginBottom: '50px' }}>Our Valued Partners</h2>
                <PartnersCarousel />
            </Grid>
            <Grid id='get-started' container justifyContent="center">
                <GeneralTitle rowData={{ title:"Let's Work Together to Make your Business Scaling Up!", contents:[] }}/>
                <SignUpForm/>
            </Grid>
        </Grid>
    );
}

export default Home;